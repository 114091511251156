import { component } from 'tsdi';
import { WrapRequest, wrapRequest } from 'wrap-request';
import { makeAutoObservable } from 'mobx';
import { injectTSDI } from './tsdi';
import { Api, CountryCode } from './api';
import { ConnectApiStudioDetailsDto, ConnectApiStudioDtoV2 } from './api/dtos';
import { assertDefined } from './error';

export interface StudioRequests {
    studioRequest: WrapRequest<ConnectApiStudioDetailsDto>;
    allStudiosRequest: WrapRequest<ConnectApiStudioDtoV2[]>;
}

@component
export class StudioStore implements StudioRequests {
    constructor() {
        makeAutoObservable(this);
    }

    private api = injectTSDI(Api);

    private studioId?: number;

    public getStudioId = () => {
        if (!this.studioId) {
            throw new Error('No studio id!');
        }
        return this.studioId;
    };

    public hasStudioId = () => Boolean(this.studioId);

    public setStudioId = async (studioId: number) => {
        this.studioId = studioId;
        await this.studioRequest.request(studioId);
    };

    public resetStudioId = () => {
        this.studioId = undefined;
        this.studioRequest.reset(undefined);
    };

    public studioRequest = wrapRequest(
        (studioId: number) =>
            this.api.client.OnlinePagesStudioController.getStudioDetails(
                studioId
            ),
        {
            cacheKey: 'studio-request',
            defaultData: {
                id: 0,
                studioName: ''
            }
        }
    );

    public allStudiosRequest = wrapRequest(
        () => this.api.client.OnlinePagesStudioController.getStudioList({}),
        { cacheKey: 'all-studios-request', defaultData: [] }
    );

    public get referralProgramActive() {
        return this.studioRequest.result?.referralProgramActive;
    }

    public get germanStudio() {
        return this.studioCountryCode === 'DE';
    }

    public get studioAddress() {
        return this.studioRequest.result?.address;
    }

    public get studioName() {
        return this.studioRequest.result?.studioName ?? '';
    }

    public get studioCurrency() {
        return this.studioRequest.result?.currencyCode || 'UNDEFINED';
    }

    public get studioLocale() {
        return this.studioRequest.result?.defaultLocale || undefined;
    }

    public get studioCountryCode() {
        return this.studioAddress?.countryCodeAlpha2 as CountryCode | undefined;
    }

    public get studioLegalLinks() {
        return this.studioRequest.result?.centralLegalLinks ?? [];
    }

    public get studioTimezone() {
        return (
            (this.studioRequest.result?.zoneId as string | undefined) ||
            'Europe/Berlin'
        );
    }

    public get trialSessionBookable() {
        return Boolean(this.studioRequest.result?.trialSessionBookable);
    }

    public waitForStudioTimezone = async () => {
        await this.studioRequest.when();
        if (!this.studioTimezone) {
            throw new Error('No studio zoneId');
        }
        return this.studioTimezone;
    };

    public get studioOpeningHours() {
        return this.studioRequest.result?.openingHours;
    }

    public get trialSessionHours() {
        return this.studioRequest.result?.trialSessionHours;
    }

    public resolveStudio = (studio: ConnectApiStudioDtoV2 | 'current') => {
        if (studio === 'current') {
            assertDefined(
                this.studioRequest.result,
                'Current studio information not fetched yet!'
            );
            return this.studioRequest.result;
        }
        return studio;
    };

    public resolveStudioId = (studioId: number | 'current') => {
        if (studioId === 'current') {
            assertDefined(this.studioId, 'Current studioId not available!');
            return this.studioId;
        }
        return studioId;
    };
}
