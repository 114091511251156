import { Lambda, reaction } from 'mobx';
import { component, initialize, TSDI } from 'tsdi';
import { WrapRequest } from 'wrap-request';
import * as React from 'react';
import { ApiError } from '@api/api-error';
import { ErrorCode } from '../components/error-handler/store';
import { apiError, errorCode } from '../components/error-handler';
import { injectTSDI } from './tsdi';

const DISPOSERS = Symbol('Disposers');
const DISPOSER_PROPS = Symbol('Disposer Props');

interface InnerDisposable {
    [DISPOSERS]?: Lambda[];
}

type DisposableMethods = () => Lambda | Lambda[];

function init(disposable: InnerDisposable): void {
    const disposeMethods: DisposableMethods[] | undefined =
        Object.getPrototypeOf(disposable)[DISPOSER_PROPS];
    if (!disposeMethods) {
        return;
    }

    disposable[DISPOSERS] = disposeMethods.reduce(
        (all: Lambda[], disposeMethod: DisposableMethods) => {
            const disposers = disposeMethod.call(disposable);
            if (Array.isArray(disposers)) {
                all.push(...disposers);
            } else if (disposers) {
                all.push(disposers);
            }
            return all;
        },
        []
    );
}

function destroy(disposable: InnerDisposable): void {
    const disposeMethods = Object.getPrototypeOf(disposable)[DISPOSER_PROPS];
    if (!disposeMethods) {
        return;
    }

    const disposers = disposable[DISPOSERS];
    if (disposers) {
        disposers.forEach((d) => d());
    }
    disposable[DISPOSERS] = undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function addDisposerProp(prototype: any, property: string | symbol): void {
    const old = prototype[DISPOSER_PROPS];
    if (old) {
        prototype[DISPOSER_PROPS] = [...old, prototype[property]];
    } else {
        prototype[DISPOSER_PROPS] = [prototype[property]];
    }
}

@component
export class TsdiLifeCycle {
    private get tsdi() {
        return injectTSDI(TSDI);
    }

    @initialize
    protected init(): void {
        const { onCreate, onDestroy } = this;
        this.tsdi.addLifecycleListener({ onCreate, onDestroy });
    }

    protected onCreate = (component: InnerDisposable): void => {
        if (!component) {
            return;
        }
        init(component);
    };

    protected onDestroy = (component: InnerDisposable): void => {
        if (!component) {
            return;
        }
        const disposer = Object.getPrototypeOf(component);
        if (!disposer) {
            return;
        }
        destroy(component);
    };
}

function reactdisposer<T extends DisposableMethods>(
    prototype: React.Component & InnerDisposable,
    property: string | symbol,
    // needed for generic type safety
    _descriptor: TypedPropertyDescriptor<T>
): void {
    const { componentDidMount, componentWillUnmount } = prototype;

    addDisposerProp(prototype, property);

    prototype.componentDidMount = function (): void {
        init(this);
        if (componentDidMount) {
            componentDidMount.call(this);
        }
    };

    prototype.componentWillUnmount = function (): void {
        destroy(this);
        if (componentWillUnmount) {
            componentWillUnmount.call(this);
        }
    };
}

function tsdidisposer<T extends DisposableMethods>(
    prototype: object,
    property: string | symbol,
    // needed for generic type safety
    _descriptor: TypedPropertyDescriptor<T>
): void {
    addDisposerProp(prototype, property);
}

export const autodisposer = { react: reactdisposer, tsdi: tsdidisposer };

export function reactionWrapRequestError(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    wrapRequest: WrapRequest<any>
): Lambda {
    return reaction(
        () => wrapRequest.error,
        (e) => {
            if (!e) {
                return;
            }

            if (e instanceof ApiError) {
                apiError(e);
            } else {
                errorCode(e.message as ErrorCode);
            }
        }
    );
}
