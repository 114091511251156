import { component } from 'tsdi';
import { makeAutoObservable } from 'mobx';
import { ApiError } from '@api/api-error';

export type ErrorCode =
    | 'WURL' // wrong url (short or missing target page such as checkout, trial session ...)
    | 'SHNC' // there are no active studio configuration (no picked facilities locations activated)
    | 'SINC' // studio page is inactive
    | 'RBINC' // rate bundles page is inactive
    | 'TSINC' // trial session page is inactive
    | 'WSID' // studio id does not exists
    | 'AUTHERORR' // authorization error
    | 'WTID' // wrong tenant ID
    | 'URL_NOT_EXIST'; // If Endpoint is called with a non existing url you got response below

@component
export class ErrorHandlerStore {
    constructor() {
        makeAutoObservable(this);
    }

    public apiError?: ApiError;

    public errorCode?: ErrorCode;

    public errorTitle?: string;

    public errorDescription?: string;

    public traceId?: (string | undefined)[];
}
