import * as React from 'react';
import { render } from 'react-dom';

import classnames from 'classnames';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).classnames = classnames;

// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill';
import { TSDI } from 'tsdi';
import { configure as mobxConfigure } from 'mobx';
import { App } from './app';

smoothscroll.polyfill();

import 'mobx-wrap-request';
import { ErrorHandler } from './components/error-handler';

import './styles/reset.css';
import './styles/main.scss';
import { BugsnagErrorBoundary } from './core/bugsnag';
import { TsdiLifeCycle } from './core/reactions';

mobxConfigure({
    enforceActions: 'never'
});

const tsdi = new TSDI();
tsdi.enableComponentScanner();
tsdi.get(TsdiLifeCycle);

render(
    <BugsnagErrorBoundary FallbackComponent={ErrorHandler}>
        <App />
    </BugsnagErrorBoundary>,
    document.getElementById('app')
);
