import { Pretend, IPretendRequest, Get } from 'pretend';

import * as dtos from './kiwi-dtos';

export interface TenantMappingController {
    getIdentifier(tenant: string): Promise<dtos.TenantMappingDto>;

    getTenant(identifier: number): Promise<dtos.TenantMappingDto>;
}

export interface OnlinePagesKiwi {
    TenantMappingController: TenantMappingController;
}

/* eslint-disable @typescript-eslint/no-explicit-any */

class TenantMappingControllerBlueprint implements TenantMappingController {
    @Get('/api/tenant-mappings/identifier/{tenant}')
    public getIdentifier(_p0: any): any {
        /**/
    }

    @Get('/api/tenant-mappings/tenant/{identifier}')
    public getTenant(_p0: any): any {
        /**/
    }
}

/* eslint-enable @typescript-eslint/no-explicit-any */

export function getClient(
    endpoint: string,
    token: string | undefined,
    configure: (client: Pretend) => Pretend = (client) => client
): OnlinePagesKiwi {
    const bearerToken = (request: IPretendRequest) => {
        if (token) {
            request.options.headers = new Headers(request.options.headers);
            request.options.headers.set('Authorization', `bearer ${token}`);
        }
        return request;
    };

    return {
        TenantMappingController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(TenantMappingControllerBlueprint, endpoint)
    };
}
