import { ErrorResponse } from './error-response';

export class ApiError extends Error {
    private _responses: ErrorResponse[];
    private _status: number;

    constructor(
        message: string,
        status: number,
        errorResponse: ErrorResponse | ErrorResponse[]
    ) {
        super(message);
        /**
         * `Error` breaks prototype chain
         *
         * @see https://github.com/Microsoft/TypeScript/wiki/What%27s-new-in-TypeScript#support-for-newtarget
         */
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain

        this._responses = Array.isArray(errorResponse)
            ? errorResponse
            : [errorResponse];
        this._status = status;
    }

    public get errorMessages(): ErrorResponse[] {
        return this._responses;
    }

    public get errorCodes(): (string | undefined)[] {
        return this._responses.map((res) => res.errorCodes?.[0]);
    }

    public get errorCodesMessages(): (string | undefined)[] {
        return this._responses.map((res) => res.message);
    }

    public get traceId(): (string | undefined)[] {
        return this._responses.map((res) => res.traceId);
    }

    public get status(): number {
        return this._status;
    }
}
