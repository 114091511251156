import { component } from 'tsdi';
import {
    createI18nStore,
    createIcuStore,
    createLocaleStore,
    isTranslatable,
    translatable,
    Translatable
} from '../spa-frontend-i18n';
import { StudioStore } from './studio-store';
import { injectTSDI } from './tsdi';
import { getInitializedLocale } from './initialize-locale';
import { Api } from './api';

const locallySupportedLocales = [
    'cs-CZ',
    'de-DE',
    'en-GB',
    'es-ES',
    'fr-FR',
    'it-IT',
    'nl-NL',
    'pl-PL',
    'sl-SI'
];

@component
export class LocaleStore extends createLocaleStore({
    loggedIn() {
        return true;
    },
    currentRegion() {
        return 'DE';
    },
    currencyCode() {
        return injectTSDI(StudioStore)?.studioCurrency;
    },
    async getCurrentLocale() {
        return getInitializedLocale();
    }
}) {}

@component
export class I18nStore extends createI18nStore({
    locallySupportedLocales,
    isLoggedIn() {
        return true;
    },
    localeStore() {
        return injectTSDI(LocaleStore);
    },
    storage(): Storage {
        return localStorage;
    },
    async getSupportedLocales() {
        return locallySupportedLocales;
    },
    getCountries() {
        const api = injectTSDI(Api);
        return api.client.ConnectApiI18nController.getCountries({
            locale: injectTSDI(LocaleStore).locale || 'de-DE'
        });
    }
}) {}

@component
export class ICUStore extends createIcuStore({
    i18nStore() {
        return injectTSDI(I18nStore);
    }
}) {}

Translatable.configure({
    i18nStore() {
        return injectTSDI(I18nStore);
    }
});

export { Translatable, isTranslatable, translatable };
