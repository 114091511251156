import { MoneyDto, MoneyValue } from './dtos';

export const nbsp = '\u00A0';

function isString(value: MoneyValue): value is string {
    if (typeof value === 'string') {
        return true;
    }
    return false;
}

function isUndefined(value: MoneyValue): value is undefined | null {
    if (value === undefined) {
        return true;
    }
    if (value === null) {
        return true;
    }
    return false;
}

export function isMoneyDto(value: MoneyValue): value is MoneyDto {
    if (isUndefined(value)) {
        return false;
    }
    if (isString(value)) {
        return false;
    }
    if (typeof value === 'number') {
        return false;
    }
    return true;
}
