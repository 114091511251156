import * as React from 'react';

const useColor = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [color, setColor] = React.useState('transparent');

    React.useEffect(() => {
        if (ref.current) {
            const refColor = window.getComputedStyle(ref.current).color;
            setColor(refColor);
        }
    }, [ref.current]);

    return { ref, color };
};

const useLoaderStyles = (fill: string) =>
    React.useMemo(
        () =>
            [
                0.9,
                0.3,
                0.8,
                0.2,
                0.7,
                0.1,
                0.6,
                0.0,
                0.5,
                0.4
            ].map((opacity) => ({ opacity, fill, enablBackground: 'new' })),
        [fill]
    );
export const LoaderSvg = () => {
    const { ref, color } = useColor();
    const styles = useLoaderStyles(color);

    return (
        <div className="loader__inside" ref={ref}>
            <svg
                className="loader__svg"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 496 496"
                style={styles[0]}
                xmlSpace="preserve"
            >
                <circle style={styles[1]} cx="248" cy="24" r="24" />
                <circle style={styles[1]} cx="248" cy="472" r="24" />
                <circle style={styles[2]} cx="136" cy="53.6" r="24" />
                <path
                    style={styles[3]}
                    d="M380.8,430.4c6.4,11.2,2.4,25.6-8.8,32.8c-11.2,6.4-25.6,2.4-32.8-8.8c-6.4-11.2-2.4-25.6,8.8-32.8
	C359.2,415.2,374.4,419.2,380.8,430.4z"
                />
                <path
                    style={styles[4]}
                    d="M65.6,115.2c11.2,6.4,15.2,20.8,8.8,32.8c-6.4,11.2-20.8,15.2-32.8,8.8c-11.2-6.4-15.2-20.8-8.8-32.8
	S54.4,108.8,65.6,115.2z"
                />
                <path
                    style={styles[5]}
                    d="M454.4,339.2c11.2,6.4,15.2,20.8,8.8,32.8c-6.4,11.2-20.8,15.2-32.8,8.8c-11.2-6.4-15.2-20.8-8.8-32.8
	C428,336.8,442.4,332.8,454.4,339.2z"
                />
                <circle style={styles[6]} cx="24" cy="248" r="24" />
                <circle style={styles[7]} cx="472" cy="248" r="24" />
                <path
                    style={styles[8]}
                    d="M41.6,339.2c11.2-6.4,25.6-2.4,32.8,8.8c6.4,11.2,2.4,25.6-8.8,32.8c-11.2,6.4-25.6,2.4-32.8-8.8
	S30.4,346.4,41.6,339.2z"
                />
                <path
                    style={styles[9]}
                    d="M115.2,430.4c6.4-11.2,20.8-15.2,32.8-8.8c11.2,6.4,15.2,20.8,8.8,32.8c-6.4,11.2-20.8,15.2-32.8,8.8
	C112.8,456,108.8,441.6,115.2,430.4z"
                />
            </svg>
        </div>
    );
};
