import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { injectTSDI } from '../core/tsdi';
import { Api } from '../core/api';
import { LoaderFullPage } from '../components/loader';
import { errorCode } from '../components/error-handler';
import { ErrorHandlerStore } from '../components/error-handler/store';
import { DefaultSnackbar } from '../components/snackbar';

const Checkout = React.lazy(() => import('../views/checkout'));
const Landing = React.lazy(() => import('../views/landing'));
const PersonalisedOffer = React.lazy(
    () => import('../views/personalised-offer')
);

export const App = observer(() => {
    const errorStore = injectTSDI(ErrorHandlerStore);
    const { tenantConfig } = injectTSDI(Api);
    const tenantIdentifier = location.pathname.split('/')[1];

    React.useEffect(() => {
        // The error needs to be thrown within a child component to be caught by BugsnagErrorBoundary.
        if (errorStore.apiError) {
            /*
                JSON.stringify is to make all information show up in Bugsnag dashboard.
             */
            throw new Error(JSON.stringify(errorStore.apiError));
        }
        if (errorStore.errorCode) {
            throw new Error(`error code: ${errorStore.errorCode}`);
        }
    }, [errorStore.apiError, errorStore.errorCode]);

    React.useEffect(() => {
        if (tenantIdentifier) {
            void tenantConfig.request(tenantIdentifier);
        }
    }, [tenantConfig, tenantIdentifier]);

    return tenantConfig.match({
        loading: LoaderFullPage,
        fetched: () => (
            <BrowserRouter>
                <DefaultSnackbar />
                <React.Suspense fallback={<LoaderFullPage />}>
                    <Route path="/:tenantIdentifier/:pageType/:pageIdentifier">
                        {(props) => {
                            const { pageType, pageIdentifier } =
                                props.match?.params ?? {};

                            if (!pageIdentifier || !props.match) {
                                throw errorCode('WURL');
                            }

                            switch (pageType) {
                                case 'checkout':
                                    return (
                                        <Checkout
                                            pageIdentifier={pageIdentifier}
                                        />
                                    );
                                case 'landing':
                                    return (
                                        <Landing
                                            pageIdentifier={pageIdentifier}
                                        />
                                    );
                                case 'offer':
                                    return (
                                        <PersonalisedOffer
                                            pageIdentifier={pageIdentifier}
                                        />
                                    );
                                default:
                                    throw errorCode('WURL');
                            }
                        }}
                    </Route>
                </React.Suspense>
            </BrowserRouter>
        )
    });
});
