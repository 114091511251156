import { Constructable, external, inject, TSDI } from 'tsdi';

export let injectTSDI = (() => {
    @external
    class Injector {
        @inject
        private tsdi!: TSDI;
        public get<T>(Dependency: Constructable<T>): T {
            return this.tsdi.get(Dependency);
        }
    }
    let injector: Injector | undefined;

    function ensureInjector(): Injector {
        if (!injector) {
            injector = new Injector();
        }
        return injector;
    }

    return <T>(Dependency: Constructable<T>): T => {
        injector = new Injector();
        const injectFunction = (dependency: Constructable<T>) =>
            ensureInjector().get(dependency);
        injectFunction.reset = () => {
            injector = undefined;
        };
        injectTSDI = injectFunction;
        return injectTSDI(Dependency);
    };
})();
