import { observer } from 'mobx-react';
import * as React from 'react';
import { I18nStore } from '../../core/i18n-store';
import { injectTSDI } from '../../core/tsdi';
import { ErrorCode } from './store';

import './error-handler.scss';

type ErrorViewProps = {
    errorTitle?: string;
    errorDescription?: string;
    errorCode?: ErrorCode | string;
    traceId?: (string | undefined)[];
};

export const ErrorView = observer((props: ErrorViewProps) => {
    const { translate } = injectTSDI(I18nStore);

    const onRefreshClick = () => {
        window.location.reload();
    };

    const onHomeClick = () => {
        const tenantIdentifier = location.pathname.split('/')[1];
        const pageType = location.pathname.split('/')[2];
        const pageConfigName = location.pathname.split('/')[3];

        window.location.replace(
            `${window.location.origin}/${tenantIdentifier}/${pageType}/${pageConfigName}`
        );
    };

    return (
        <div className="error-container">
            <div className="error-content">
                <h1 className="error-title">{translate(props.errorTitle)}</h1>
                <p className="error-description">
                    {translate(props.errorDescription)}
                </p>
                {props.errorCode && (
                    <p className="error-code">(ErrorCode {props.errorCode})</p>
                )}
                {(props.traceId || []).map((id) => (
                    <p key={id} className="error-code">
                        Trace ID: {id}
                    </p>
                ))}
                <div className="error-buttons">
                    <button className="refresh-button" onClick={onRefreshClick}>
                        Refresh
                    </button>
                    <button className="home-button" onClick={onHomeClick}>
                        Home
                    </button>
                </div>
            </div>
        </div>
    );
});
