import { computed } from 'mobx';

import './plural-rules-polyfill';
import { I18nStore } from './create-i18n-store';
import type { TemporalUnitAsStringEnum } from './dtos';

export type PluralRule = 'zero' | 'one' | 'two' | 'few' | 'many' | 'other';
export type PluralRuleOptions = { [key in PluralRule]?: string };

function findRule(pluralRule: PluralRule, opts: PluralRuleOptions): string {
    const entries = Object.entries(opts);

    if (!entries.length) {
        throw new Error('no PluralRuleOptions assigned');
    }

    const group = entries.find(([rule]) => rule === pluralRule);
    if (group) {
        return group[1] || '';
    }
    if (opts.other) {
        return opts.other;
    }
    return entries[0][1] || '';
}

interface CreateIcuStoreConfig {
    i18nStore(): I18nStore;
}

export const createIcuStore = (config: CreateIcuStoreConfig) => {
    class IcuStore {
        private get i18n() {
            return config.i18nStore();
        }

        @computed
        private get pluralRules(): Intl.PluralRules {
            return new Intl.PluralRules(this.i18n.currentLocale);
        }

        public translateByQuantity = (
            count: number | undefined,
            opts: PluralRuleOptions
        ): string => {
            const { i18n, pluralRules } = this;
            const { translate, formatDecimal } = i18n;

            const pluralRule = pluralRules.select(count || 0);
            const noun = findRule(pluralRule as PluralRule, opts);

            return translate(noun, formatDecimal(count));
        };

        public translateSuffixByQuantity = (
            count: number | undefined,
            opts: PluralRuleOptions
        ): string => {
            const { i18n, pluralRules } = this;
            const { translate } = i18n;

            const pluralRule = pluralRules.select(count || 0);
            const noun = findRule(pluralRule as PluralRule, opts);

            return translate(noun);
        };

        public translateFacilities = (
            facilityCount: number | undefined
        ): string =>
            this.translateByQuantity(facilityCount, {
                one: 'facilities.one',
                other: 'facilities.some'
            });

        public translateBookings = (bookingCount: number | undefined): string =>
            this.translateByQuantity(bookingCount, {
                one: 'commons.bookings.one',
                other: 'commons.bookings.some'
            });

        public translateTerm = (
            term: TemporalUnitAsStringEnum,
            count: number | undefined
        ): string => {
            if (term === 'DAY') {
                return this.translateByQuantity(count, {
                    one: 'commons.days.one',
                    other: 'commons.days.some'
                });
            } else if (term === 'WEEK') {
                return this.translateByQuantity(count, {
                    one: 'commons.weeks.one',
                    other: 'commons.weeks.some'
                });
            } else if (term === 'MONTH') {
                return this.translateByQuantity(count, {
                    one: 'commons.months.one',
                    other: 'commons.months.some'
                });
            }
            return this.translateByQuantity(count, {
                one: 'commons.years.one',
                other: 'commons.years.some'
            });
        };

        public translateTermSuffix = (
            term: TemporalUnitAsStringEnum,
            count: number | undefined
        ): string => {
            if (term === 'DAY') {
                return this.translateSuffixByQuantity(count, {
                    one: 'commons.day',
                    other: 'commons.days'
                });
            } else if (term === 'WEEK') {
                return this.translateSuffixByQuantity(count, {
                    one: 'commons.week',
                    other: 'commons.weeks'
                });
            } else if (term === 'MONTH') {
                return this.translateSuffixByQuantity(count, {
                    one: 'commons.month',
                    other: 'termunit.months'
                });
            }
            return this.translateSuffixByQuantity(count, {
                one: 'commons.year',
                other: 'commons.years'
            });
        };

        public translateTermOnlySuffixForSingular = (
            term: TemporalUnitAsStringEnum,
            count: number | undefined
        ) => {
            if (count === 1) {
                return this.translateTermSuffix(term, count);
            }
            return this.translateTerm(term, count);
        };
    }

    return IcuStore;
};
