import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ApiError } from '@api/api-error';
import { injectTSDI } from '../../core/tsdi';
import { ErrorCode, ErrorHandlerStore } from './store';
import { ErrorView } from './error-view';

// to be considered: maybe at least some of these errors shouldn't throw an error visible for Bugsnag?
const PAGE_NOT_FOUND_ERROR_CODES: ErrorCode[] = [
    'WURL',
    'SHNC',
    'SINC',
    'RBINC',
    'TSINC',
    'WSID',
    'WTID',
    'URL_NOT_EXIST'
];

const contractErrors = ['PDPR', 'EMOD', 'WCOD', '8FEL', 'DFOC'];

const containsStringFromArray = (message: string) => {
    for (const searchString of contractErrors) {
        if (message.toLowerCase().includes(searchString.toLowerCase())) {
            return true;
        }
    }
    return false;
};

export const errorCode = (errorCode: ErrorCode) => {
    const store = injectTSDI(ErrorHandlerStore);

    const isPageNotFoundCode = PAGE_NOT_FOUND_ERROR_CODES.includes(errorCode);

    store.errorCode = errorCode;
    store.errorTitle = isPageNotFoundCode
        ? 'onlinepages.not.found.title'
        : 'onlinepages.api.error.title';

    store.errorDescription = isPageNotFoundCode
        ? 'onlinepages.not.found.description'
        : 'onlinepages.api.error.description';
};

export const apiError = (apiError: ApiError) => {
    const store = injectTSDI(ErrorHandlerStore);

    const errorDescription = apiError?.errorCodesMessages?.[0] ?? '';
    const isContractError = containsStringFromArray(errorDescription);

    store.apiError = apiError;
    store.errorTitle = isContractError
        ? 'onlinepages.contract.error'
        : 'onlinepages.api.error.title';
    store.errorDescription =
        errorDescription || 'onlinepages.api.error.description';
    store.traceId = apiError.traceId;
};

export const ErrorHandler = observer(() => {
    const { errorCode, errorDescription, errorTitle, traceId } =
        injectTSDI(ErrorHandlerStore);

    return (
        <ErrorView
            errorCode={errorCode}
            errorTitle={errorDescription}
            errorDescription={errorTitle}
            traceId={traceId}
        />
    );
});
