import { component } from 'tsdi';
import { makeAutoObservable } from 'mobx';
import { injectTSDI } from '../../core/tsdi';
import { I18nStore } from '../../core/i18n-store';

@component
export class SnackbarStore {
    private get i18nStore() {
        return injectTSDI(I18nStore);
    }

    constructor() {
        makeAutoObservable(this);
    }

    private _messageTextKey?: string;
    private _autoHideDuration = 2000;
    private _open = false;
    private _closable = false;

    public callSnackbar = (
        messageTextKey: string,
        autoHideDuration?: number,
        isClosable?: boolean
    ) => {
        this._messageTextKey = messageTextKey;
        if (autoHideDuration) {
            this._autoHideDuration = autoHideDuration;
        }
        this._open = true;
        this._closable = Boolean(isClosable);
    };

    public get messageText() {
        return this.i18nStore.translate(this._messageTextKey);
    }

    public get duration() {
        return this._autoHideDuration;
    }

    public closeSnackbar = () => {
        this._open = false;
        this._messageTextKey = undefined;
    };

    public get isOpen() {
        return this._open;
    }

    public get closable() {
        return this._closable;
    }
}
