import { component, initialize } from 'tsdi';
import { makeAutoObservable, reaction } from 'mobx';
import { injectTSDI } from '../tsdi';
import { TenantInfoStore } from '../tenant-info-store';
import { autodisposer } from '../reactions';
import { TenantApplicationBrandType } from './brand-type';

export const BRANDS = ['magicline', 'perfectgym'] as const;
export type BrandName = (typeof BRANDS)[number];

@component
export class MultiBrandStore {
    private get tenantInfoStore() {
        return injectTSDI(TenantInfoStore);
    }

    public brandType = TenantApplicationBrandType.MAGICLINE;

    @initialize
    protected init() {
        makeAutoObservable(this);
    }

    @autodisposer.tsdi
    protected reactions() {
        return reaction(
            () => this.tenantInfoStore.tenantMultiBrandType,
            (brandName) => {
                if (brandName) {
                    this.brandType = this.getBrandTypeByBrandName(brandName);
                }
            },
            { fireImmediately: true }
        );
    }

    private getBrandTypeByBrandName(
        brand: BrandName | undefined | null
    ): TenantApplicationBrandType {
        switch (brand) {
            case 'perfectgym':
                return TenantApplicationBrandType.PERFECTGYM;
            case 'magicline':
                return TenantApplicationBrandType.MAGICLINE;
            default:
                return TenantApplicationBrandType.MAGICLINE;
        }
    }
}
