import { observer } from 'mobx-react';
import { Button, Snackbar } from '@mui/material';
import React from 'react';
import { injectTSDI } from '../../core/tsdi';
import { I18nStore } from '../../core/i18n-store';
import { SnackbarStore } from './store';

const ActionButton = () => {
    const { translate } = injectTSDI(I18nStore);
    const { closeSnackbar } = injectTSDI(SnackbarStore);

    return (
        <Button
            variant="text"
            color="error"
            size="small"
            onClick={() => closeSnackbar()}
        >
            {translate('onlinepages.ok.all.clear')}
        </Button>
    );
};

export const DefaultSnackbar = observer(() => {
    const { duration, messageText, isOpen, closeSnackbar, closable } =
        injectTSDI(SnackbarStore);

    return (
        <Snackbar
            message={messageText}
            open={isOpen}
            onClose={() => closeSnackbar()}
            autoHideDuration={duration}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            action={closable ? <ActionButton /> : undefined}
        />
    );
});
