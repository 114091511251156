if (!Intl.PluralRules) {
    interface PluralRulesOptions {
        type: 'cardinal' | 'ordinal';
    }
    class PluralRules {
        constructor(
            _locales?: string | string[],
            options?: PluralRulesOptions
        ) {
            if (options && options.type === 'ordinal') {
                console.warn(
                    'PluralRules pollyfill no support for type: ordinal'
                );
            }
        }
        public select(nr: number): string {
            if (nr === 1) {
                return 'one';
            }
            return 'other';
        }

        public supportedLocalesOf(
            locales?: string | string[],
            _options?: PluralRulesOptions
        ): string[] {
            if (!locales) {
                return [];
            }
            if (Array.isArray(locales)) {
                return locales;
            }
            return [locales];
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (Intl as any).PluralRules = PluralRules;
}
