import { component, initialize } from 'tsdi';
import { Api } from '@api/index';
import { makeAutoObservable, reaction } from 'mobx';
import { wrapRequest } from 'wrap-request';
import { injectTSDI } from './tsdi';
import { BrandName } from './brand/store';
import { autodisposer } from './reactions';

@component
export class TenantInfoStore {
    private get api() {
        return injectTSDI(Api);
    }

    @initialize
    protected init() {
        makeAutoObservable(this);
    }

    @autodisposer.tsdi
    protected reactions() {
        return reaction(
            () => this.api.tenantName,
            () => this.tenantInfoRequest.request(),
            { fireImmediately: true }
        );
    }

    private tenantInfoRequest = wrapRequest(() =>
        this.api.client.ConnectApiTenantController.getTenantInfo()
    );

    public get tenantMultiBrandType(): BrandName | undefined {
        if (!this.tenantInfoRequest.result) {
            return;
        }
        return this.tenantInfoRequest.result?.tenantMultiBrandType ===
            'PERFECT_GYM'
            ? 'perfectgym'
            : 'magicline';
    }
}
