import { computed, observable } from 'mobx';
import { component } from 'tsdi';
import { Pretend } from 'pretend';
import { wrapRequest } from 'wrap-request';
import { apiError, errorCode } from '../../components/error-handler';
import { ApiError } from './api-error';
import * as apiKiwi from './kiwi-client';
import * as apiOnlinePages from './client';
import {
    CommonTrialSessionLeadAddressDto,
    ConnectApiRateBundleDto
} from './dtos';
import { ErrorResponse } from './error-response';

const hostname = window.location.hostname;

export const isLocal = () => hostname.includes('.local.');
export const isDev = () => hostname.includes('.dev.');
export const isStage = () => hostname.includes('.stage.');
export const isRef = () => hostname.includes('.ref.');
export const isProd = () => !isDev() && !isStage() && !isRef();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isE2e = () => Boolean((window as any).Cypress);

const env = (() => {
    if (isLocal()) {
        return '.local';
    }
    if (isDev()) {
        return '.dev';
    }
    if (isStage()) {
        return '.stage';
    }
    if (isRef()) {
        return '.ref';
    }
    return '';
})();

const port = isLocal() ? ':9443' : '';

export type CountryCode = NonNullable<
    CommonTrialSessionLeadAddressDto['country']
>;

export type PreuseType = NonNullable<ConnectApiRateBundleDto['preuseType']>;

@component
export class Api {
    @observable
    public authenticationError = false;

    @computed
    public get kiwiApiUrl() {
        return `https://online-pages${env}.magicline.com${port}`;
    }

    @computed
    public get clientKiwi() {
        return apiKiwi.getClient(this.kiwiApiUrl, undefined, (pretend) =>
            this.configureClient(pretend)
        );
    }

    @computed
    public get apiUrl() {
        if (!this.tenantName) {
            console.error(
                'Missing tenant name. Check if tenant config has been already fetched'
            );
            throw errorCode('WTID');
        }

        return `https://${this.tenantName}.api${env}.magicline.com${port}/`;
    }

    @computed
    public get client() {
        return apiOnlinePages.getClient(this.apiUrl, undefined, (pretend) =>
            this.configureClient(pretend)
        );
    }

    public tenantConfig = wrapRequest(
        (identifier: string) =>
            this.clientKiwi.TenantMappingController.getTenant(
                parseInt(identifier, 10)
            ),
        {
            defaultData: {}
        }
    );

    public get tenantName() {
        return this.tenantConfig.$.tenant;
    }

    private configureClient(pretend: Pretend): Pretend {
        pretend.requestInterceptor((request) => {
            request.options.headers = new Headers(request.options.headers);
            if (!request.options.headers.has('Content-Type')) {
                request.options.headers.set('Content-Type', 'application/json');
            } else if (
                request.options.headers.get('Content-Type') ===
                'multipart/form-data'
            ) {
                // note: strange but true, the fetch API sets the
                // content-type header on its own
                request.options.headers.delete('Content-Type');
            }
            return request;
        });
        pretend.decode(async (response) => {
            if (response.status === 401) {
                this.authenticationError = true;
                throw errorCode('AUTHERORR');
            } else if (response.status === 400) {
                const error = new ApiError(
                    response.statusText,
                    response.status,
                    (await response.json()) as ErrorResponse | ErrorResponse[]
                );

                const bookingError = error.errorCodes.find(
                    (message) =>
                        message === 'TRIALSESSION_ALREADY_BOOKED' ||
                        message === 'ACTIVE_MEMBER_CANNOT_BOOK_TRIAL_SESSION'
                );

                if (bookingError) {
                    throw new Error(error.errorCodesMessages[0]);
                }

                const urlNotExists = error.errorCodesMessages.find(
                    (message) => message === 'ONLINEPAGES_URL_NOT_EXIST'
                );
                if (urlNotExists) {
                    throw errorCode('URL_NOT_EXIST');
                }

                throw apiError(error);
            } else if (response.status === 404) {
                throw errorCode('WTID');
            } else if (response.status >= 400) {
                const error = new ApiError(
                    response.statusText,
                    response.status,
                    (await response.json()) as ErrorResponse | ErrorResponse[]
                );
                throw apiError(error);
            }

            try {
                return await response.json();
            } catch (e) {
                // assume there is no body we can convert to json
                return undefined;
            }
        });
        return pretend;
    }
}
