import Bugsnag, { NotifiableError } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as React from 'react';

import { isProd, isDev } from './api';

function getReleaseState() {
    return isProd() ? 'production' : 'development';
}

const bugsnagClient = Bugsnag.start({
    autoTrackSessions: false,
    apiKey: '2d162769714b05fd0c3eed24c74edff2',
    collectUserIp: false,
    plugins: [new BugsnagPluginReact(React)],
    onSession: (event) => {
        if (event.app) {
            event.app.releaseStage = getReleaseState();
        }
    },
    onError: (event) => {
        if (isDev()) {
            return false;
        }

        event.app.releaseStage = getReleaseState();

        event.errors.forEach((error) => {
            if (error.errorMessage.startsWith('info:')) {
                error.errorClass = 'Info';
            }
        });

        return true;
    }
});

const bugsnag = bugsnagClient.getPlugin('react')!;

export const bugsnagNotify = (error: NotifiableError) => {
    bugsnagClient?.notify(error);
};

const BugsnagErrorBoundary = bugsnag.createErrorBoundary(React);

export { bugsnag, BugsnagErrorBoundary };
