import * as React from 'react';

import './loader.scss';
import { observer } from 'mobx-react';
import { LoaderSvg } from './loader-svg';

interface LoaderProps {
    size: 'regular' | 'fullpage' | 'contain';
    color: 'grey' | 'inherit';
    className?: string;
}

export const Loader = observer(function Loader(props: LoaderProps) {
    const { className, size, color = 'grey' } = props;

    const classNames = classnames('loader', className, {
        'loader--regular': size === 'regular',
        'loader--fullpage': size === 'fullpage',
        'loader--contain': size === 'contain',

        'loader--color-grey': color === 'grey',
        'loader--color-inherit': color === 'inherit'
    });

    return (
        <div className={classNames}>
            <LoaderSvg />
        </div>
    );
});

export const LoaderFullPage = () => <Loader size="fullpage" color="grey" />;
