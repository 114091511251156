import { computed } from 'mobx';
import { external } from 'tsdi';
import { I18nStore } from './create-i18n-store';
import { nbsp } from './util';

interface TranslatableConfiguration {
    i18nStore(): I18nStore;
    isNoI18nMode?(): boolean;
}

@external
export class Translatable {
    private static _configuration?: TranslatableConfiguration;

    private get translate() {
        return Translatable._configuration?.i18nStore().translate || (() => '-');
    }

    private get isNoI18nMode() {
        return Translatable._configuration?.isNoI18nMode?.() ?? false;
    }

    public static configure(configuration: TranslatableConfiguration): void {
        Translatable._configuration = configuration;
    }

    private translationKey: string;
    private params: unknown[];

    constructor(translationKey: string, ...params: unknown[]) {
        this.translationKey = translationKey;
        this.params = params;
    }

    @computed
    public get translation(): string {
        const { translate, isNoI18nMode } = this;
        if (isNoI18nMode) {
            return this.debug;
        }

        const { params, translationKey } = this;
        const ps = params.map(p => (isTranslatable(p) ? p.translation : p));

        return translate(translationKey, ...ps);
    }

    @computed
    public get debug(): string {
        const { params, translationKey } = this;
        if (!params || !params.length) {
            return translationKey;
        }

        const p = Object.values(params).map(p => {
            if (isTranslatable(p)) {
                return p.debug;
            }
            return p;
        });
        return `${translationKey}${nbsp}[${p.join(`,${nbsp}`)}]`;
    }

    public toString(): string {
        return this.translation;
    }
}

export function translatable(
    translationKey: string,
    ...params: unknown[]
): Translatable {
    return new Translatable(translationKey, ...params);
}

export function isTranslatable(
    translatation: unknown
): translatation is Translatable {
    if (typeof translatation === 'object' && translatation !== null) {
        return Object.getPrototypeOf(translatation) === Translatable.prototype;
    }
    return false;
}
