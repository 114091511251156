import {
    computed,
    observable,
    reaction,
    Lambda,
    when,
    makeObservable
} from 'mobx';
import { initialize, destroy } from 'tsdi';
import { getInitializedLocale } from '../core/initialize-locale';
import { CurrencyCodeEnum, CountryCodeEnum, MoneyDto } from './dtos';
import { isMoneyDto } from './util';

type SignPosition = 'PREFIX' | 'SUFFIX';

const ANY_NUMBER = 23;

export interface CreateLocaleStoreConfig {
    canSetCurrentLocale?: boolean;

    loggedIn(): boolean;
    currentRegion(): CountryCodeEnum;
    currencyCode(): CurrencyCodeEnum | undefined;

    getCurrentLocale(): Promise<string>;
}

export type LocaleStore = ReturnType<typeof createLocaleStore>['prototype'];

export const createLocaleStore = (config: CreateLocaleStoreConfig) => {
    const { canSetCurrentLocale = false } = config;

    class LocaleStore {
        constructor() {
            makeObservable(this, {
                locale: observable,
                _userLanguage: observable,
                _userCountry: observable,
                currentLocale: computed,
                currentLang: computed,
                userCountry: computed,
                currentRegion: computed,
                currencyCode: computed,
                decimalSymbol: computed,
                thousandsSeparatorSymbol: computed,
                currencyPosition: computed,
                currencyPrefix: computed,
                currencySuffix: computed,
                percentPosition: computed,
                percentPrefix: computed,
                percentSuffix: computed
            });
        }

        public locale?: string;
        public _userLanguage?: string;
        public _userCountry?: CountryCodeEnum;

        private disposers: Lambda[] = [];

        public readonly localeLoaded = new Promise<void>((resolve) =>
            when(() => Boolean(this.locale), resolve)
        );

        @initialize
        protected init(): void {
            this.disposers.push(
                reaction(
                    () => ({
                        loggedIn: config.loggedIn(),
                        locale: getInitializedLocale()
                    }),
                    (loggedIn) => loggedIn && this.reload(),
                    { fireImmediately: true }
                )
            );
        }

        @destroy
        protected onDestroy(): void {
            this.disposers.forEach((disposer) => disposer());
        }

        private async reload(): Promise<void> {
            this.setCurrentLocale(await config.getCurrentLocale());
        }

        public get currentLocale(): string | undefined {
            return this.locale;
        }

        public set currentLocale(locale: string | undefined) {
            if (!canSetCurrentLocale) {
                throw new Error('setting current locale is not allowed');
            }
            if (!locale) {
                throw new Error('locale is required');
            }
            this.setCurrentLocale(locale);
        }

        private setCurrentLocale(locale: string): void {
            const [lang, country] = locale.split('-');

            this.locale = locale;
            this._userLanguage = lang;
            this._userCountry = country as CountryCodeEnum;
        }

        public get currentLang(): string {
            const { _userLanguage } = this;
            if (!_userLanguage) {
                return 'de';
            }
            return _userLanguage;
        }

        public get userCountry(): CountryCodeEnum | undefined {
            return this._userCountry;
        }

        public get currentRegion(): string {
            const region = config.currentRegion();
            return region === 'UNDEFINED' ? 'DE' : region;
        }

        public get currencyCode(): CurrencyCodeEnum {
            return config.currencyCode() ?? 'EUR';
        }

        private getLocaledCurrencySign = ([locale, currency]: [
            string,
            CurrencyCodeEnum
        ]): string => {
            if (currency === 'UNDEFINED') {
                return 'UNDEFINED';
            }
            const str = Number(ANY_NUMBER).toLocaleString(locale, {
                currency,
                style: 'currency',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
            return str.replace(`${ANY_NUMBER}`, '').trim();
        };

        public getCurrencySign = (currency: CurrencyCodeEnum): string => {
            const { currentLocale = 'de-DE' } = this;
            return this.getLocaledCurrencySign([currentLocale, currency]);
        };

        public getCurrencyPrefix = (currency: CurrencyCodeEnum): string => {
            const { currencyPosition } = this;
            const sign = this.getCurrencySign(currency);
            return currencyPosition === 'PREFIX' ? `${sign}` : '';
        };

        public getCurrencySuffix = (currency: CurrencyCodeEnum): string => {
            const { currencyPosition } = this;
            const sign = this.getCurrencySign(currency);
            return currencyPosition === 'SUFFIX' ? ` ${sign}` : '';
        };

        public get decimalSymbol(): string {
            const seperator = Number(3.5)
                .toLocaleString(this.currentLocale)
                .replace('3', '')
                .replace('5', '');
            return seperator.length === 1 ? seperator : '.';
        }

        public get thousandsSeparatorSymbol(): string {
            const seperator = Number(123456)
                .toLocaleString(this.currentLocale)
                .replace('123', '')
                .replace('456', '');
            return seperator.length === 1 ? seperator : '';
        }

        public get currencyPosition(): SignPosition {
            const str = Number(123).toLocaleString(this.currentLocale, {
                style: 'currency',
                currency: 'FOO'
            });
            const index = str.indexOf('FOO');
            return index < str.length * 0.5 ? 'PREFIX' : 'SUFFIX';
        }

        public get currencyPrefix(): string {
            return this.getCurrencyPrefix(this.currencyCode);
        }

        public get currencySuffix(): string {
            return this.getCurrencySuffix(this.currencyCode);
        }

        public get percentPosition(): SignPosition {
            const str = Number(1).toLocaleString(this.currentLocale, {
                style: 'percent'
            });
            const index = str.indexOf('%');
            return index < str.length * 0.5 ? 'PREFIX' : 'SUFFIX';
        }

        public get percentPrefix(): string {
            return this.percentPosition === 'PREFIX' ? '% ' : '';
        }

        public get percentSuffix(): string {
            return this.percentPosition === 'SUFFIX' ? ' %' : '';
        }

        public formatCurrency = (
            value: number | MoneyDto | undefined
        ): string => {
            if (value === undefined) {
                return '-';
            }
            const { currentLocale, currencyCode } = this;
            const amount = isMoneyDto(value) ? value.amount : value || 0;
            const currCode: CurrencyCodeEnum | undefined =
                isMoneyDto(value) && value.currencyCode !== 'UNDEFINED'
                    ? value.currencyCode
                    : currencyCode;

            if (!currCode) {
                return Number(amount).toLocaleString(currentLocale);
            }
            return Number(amount).toLocaleString(currentLocale, {
                style: 'currency',
                currency: currCode
            });
        };
    }

    return LocaleStore;
};
